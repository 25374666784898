<template>
  <b-card no-body class="p-1">
    <b-overlay :show="loading">
      <div class="demo-spacing-0 d-flex justify-content-between m-1" dir="rtl">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
          <feather-icon size="16" icon="FilterIcon"/>
          فیلتر
        </b-button>
<!--        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info"
                  @click="$refs['msg-modal'].toggle()">
          <feather-icon size="17" icon="MailIcon"/>
          ارسال پیام جدید
        </b-button>-->
      </div>

      <search-and-filter
          :is-active.sync="isActive"
          :options="columns"
          @filter="getData(1, perPage)"
          @reset="getData(1, perPage)"
      />

      <b-modal
          ref="msg-modal"
          cancel-variant="outline-secondary"
          ok-title="ارسال پیام"
          cancel-title="لغو"
          centered
          title="پیام جدید"
          @ok.prevent="sendMessage"
          v-model="modal"
          size="lg"
      >
        <b-form>
          <b-overlay :show="loading">
            <b-form-group label="عنوان پیام">
              <b-form-input
                  type="text"
                  placeholder="عنوان"
                  v-model="message.title"
              />
            </b-form-group>
            <b-form-group label="متن پیام">
              <b-form-textarea placeholder="متن پیام" v-model="message.content"/>
            </b-form-group>
          </b-overlay>
        </b-form>
      </b-modal>

      <div class="relative-over-x">
        <b-table
            ref="refUserListTable"
            :items="items"
            :small="true"
            responsive
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="اطلاعاتی یافت نشد"
            style="white-space: nowrap; min-height : 235px"
            @sort-changed="sort($event)"
            no-local-sort
        >

          <template #cell(read)="data">
            <b-badge
                pill
                :variant="'light-'+statusVariant(data.item.read)"
                class="text-capitalize"
                :badge="12"
            >
              {{ statusLabel(data.item.read) }}
            </b-badge>
          </template>

          <template #cell(content)="data">
            <p class="ellipsis cursor-pointer mb-0" :show="show[data.item.id]"
               @click.prevent="fullShow(data.item.id)">
              {{ data.item.content }}</p>
          </template>
          <template #cell(createdAtDateTime)="data">
                        <span dir="ltr">
                            {{ $G2J(data.item.createdAtDateTime) }}
                        </span>
          </template>

        </b-table>
      </div>

      <!-- pagination -->
      <div
          class="demo-spacing-0 d-flex justify-content-between m-1"
      >
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            align="left"
            @input="getData(currentPage,perPage)"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
        <div>
          <v-select
              id="perpage"
              v-model="perPage"
              dir="rtl"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              style="min-width: 85px"
              @input="getData(1,perPage)"
          />
          <label for="perpage">تعداد در صفحه</label>
        </div>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination,
  BCard,
  BButton,
  BTable,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BModal,
  BOverlay,
  // BDropdown,
  // BDropdownItem,
  BBadge,
  // BAvatar,
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';

export default {
  name: 'Messages',
  components: {
    SearchAndFilter,
    BPagination,
    BCard,
    BButton,
    BTable,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BModal,
    BOverlay,
    // BDropdown,
    // BDropdownItem,
    BBadge,
    // BAvatar,
    vSelect,
  },
  data: () => ({
    test: 0,
    pageLength: 3,
    dir: false,
    searchTerm: '',
    currentPage: 1,
    perPage: 10,
    rows: 10,
    sortBy: '',
    isSortDirDesc: '',
    modal: false,
    loading: false,
    message: {
      title: '',
      content: ''
    },
    userData: [],
    show: [],
    isActive: false,
    perPageOptions: [5, 10, 20, 50, 100],
    items: [],
    columns: [
      {
        label: 'عنوان پیام',
        key: 'title',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'متعلق به',
        key: 'customerEmail',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'سایت',
        key: 'site',
      },
      {
        label: 'خوانده شده',
        key: 'read',
        sortable: false,
        searchType: 'select',
        selectOptions: [
          {label: 'خوانده نشده', value: false},
          {label: 'خوانده شده', value: true}
        ]
      },
      {
        label: 'تاریخ ایجاد پیام',
        key: 'createdAtDateTime',
        sortable: true,
        searchType: 'date'
      },
      {
        label: 'متن پیام',
        key: 'content',
        sortable: false,
      },
    ],
  }),
  computed: {
    statusVariant() {
      return e => e ? 'success' : 'danger';
    },
    statusLabel() {
      return e => e ? 'خوانده شده' : 'خوانده نشده';
    },
  },
  methods: {
    fullShow(e) {
      this.show[e] = this.show[e] ? false : true
      for (let a = 0; a < this.show.length; a++) {
        if (a !== e)
          this.show[a] = false
      }
      this.$forceUpdate()
    },
    async sendMessage() {
      if (!this.loading) {
        this.loading = true
        try {
          await this.$axios.post('/users/notifications/', this.message)
          this.loading = false
          this.message = {
            id: '',
            title: '',
            content: '',
          }
          this.messageModal = false
          this.$error('پیام ارسال شد', '', 'success')
          this.getData()
        } catch (e) {
          this.loading = false
        }
      }
    },
    sort(e) {
      console.log(e)

      let sort = this.$toSnakeCase(e.sortBy)
      // let sort = e.sortBy
      let sorting = e.sortDesc ? 'DESC' : 'ASC'

      this.$router.push({
        query: {
          ...this.$route.query,
          orderBy: sort,
          sorting: sorting
        }
      }).catch(() => {
      })

      this.getData(1, this.perPage)

    },
    async getData(page, perPage) {
      if (this.$route.query.s) {
        return this.getData1(page, perPage)
      }
      this.loading = true

      let queryParams = {
        size: Math.ceil(perPage / this.$urls.length),
        page: page,
        ...this.$route.query,
      }
      let items = []
      let rows = 0
      for (const url of this.$urls) {
        const res = await this.$axios(
            url[1] + '/users/list-notifications',
            {
              params: queryParams
            }
        )
        items.push(...res.data.content.map(e => {
          return {
            ...e,
            site: url[0]
          }
        }))
        rows += res.data.totalElements
      }
      items = items.sort((a, b) => b.createdAtDateTime.localeCompare(a.createdAtDateTime))
      this.items = items
      this.rows = rows
      this.loading = false
    },
    async getData1(page, perPage) {
      this.state.loading = true

      let queryParams = {
        size: Math.ceil(perPage / this.$urls.length),
        page: page,
        ...this.$route.query,
      }
      let items = []
      let rows = 0
      const res = await this.$axios(
          this.$urls.find(e => e[0] === this.$route.query.s)[1] + '/users/list-notifications',
          {
            params: queryParams
          }
      )
      items.push(...res.data.content.map(e => {
        return {
          ...e,
          site: this.$urls.find(e => e[0] === this.$route.query.s)[0]
        }
      }))
      rows += res.data.totalElements
      this.items = items
      this.rows = rows
      this.loading = false
    },
  },
  mounted() {
    this.getData(1, this.perPage)
  },
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}

.ellipsis {
  overflow: hidden;
  width: calc(100vw - 1000px);
  text-overflow: ellipsis;
  white-space: nowrap;

  &[show] {
    white-space: normal;
  }

  @media(max-width: 1200px) {
    width: calc(100vw - 470px);
    min-width: 200px;
  }
}
</style>
